import userService from "@/services/sistema";
// import campanhaService from "@/services/campanha";
import empresaService from "@/services/empresa";

import { v4 as uuidv4 } from "uuid";
// import store from "../../index";

export default {
  fetchUser: async ({ commit }, filters) => {
    try {
      const response = await userService.getUsers({ ...filters });
      commit("setUser", response.data);
    } catch (error) {
      console.error("Action.user/fetchUser", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchView: async ({ commit }, value) => {
    try {
      commit("setView", value);
      localStorage.setItem("titlePage", value);
    } catch (error) {
      console.error("Action.view/fetchView", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchHiddenValue: async ({ commit }, value) => {
    try {
      commit("setHiddenValue", value);
      localStorage.setItem("hiddenValue", value);
    } catch (error) {
      console.error("Action.view/fetchHiddenValue", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchPermissions: async ({ commit }, id) => {
    const getUserPermissions = userService.getUserPermissoes(id);
    const getModules = userService.getModulo({ per_page: -1 });
    const getMenus = userService.getMenu({ per_page: -1 });

    try {
      const [dataModules, dataMenus, dataPermissions] = await Promise.all([
        getModules,
        getMenus,
        getUserPermissions
      ]);

      const permissions = dataPermissions["data"];

      const userSelectedPermissions = permissions
        .filter(item => item.id_tipo_permissao != 3)
        .map(permissao => ({
          id: permissao.id_permissao,
          name: permissao.descricao_permissao
        }));

      const modules = dataModules["data"].data.reduce((accumulator, value) => {
        const menus = dataMenus["data"].data.filter(
          ({ id_modulo }) => id_modulo == value.id_modulo
        );

        const locked = value => Boolean(value);

        const customMenu = menus.map(item => {
          let children = permissions
            .filter(permission => permission.id_menu == item.id)
            .map(item => {
              if (item.id_tipo_permissao == 1)
                return {
                  id: item.id_permissao,
                  name: item.descricao_permissao,
                  locked: true
                };

              return {
                id: item.id_permissao,
                name: item.descricao_permissao
              };
            });

          return {
            id: uuidv4(),
            name: item.descricao,
            children,
            locked: !locked(children.length)
          };
        });

        let treeMenu = Object.assign(
          {},
          {
            id: uuidv4(),
            name: value.modulo,
            locked: !locked(customMenu.length),
            children: customMenu
          }
        );

        accumulator.push(treeMenu);

        return accumulator;
      }, []);

      commit("setPermissions", modules);
      commit("setUserPermissions", userSelectedPermissions);
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  },

  actionEmpresasUser: async ({ commit }, filters) => {
    try {
      let empresas = await empresaService.getEmpresas({
        ativa_campanha: "S",
        per_page: -1
      });

      let empresasUser = await userService.getUserEmpresas(filters);

      let IdEmpresasUser = empresasUser["data"].data.map(item =>
        Number(item.id_empresa)
      );

      let empresasVinculadasAoUsuario = empresas["data"].data.filter(item =>
        IdEmpresasUser.includes(item.id_empresa)
      );

      const empresasNaoVinculadasAoUsuario = empresas["data"].data.filter(
        item => !IdEmpresasUser.includes(item.id_empresa)
      );

      let empresasVinculadasComIdremocao = empresasVinculadasAoUsuario.reduce(
        (accumulator, empresa) => {
          const empresas = empresasUser["data"].data.filter(
            item => item.id_empresa == empresa.id_empresa
          );

          const customEmpresas = empresas.map(item => ({
            id: item.id,
            id_empresa: empresa.id_empresa,
            nome: empresa.nome_empresa,
            apelido: empresa.apelido,
            id_band: empresa.id_band
          }));

          let customEmpresa = Object.assign({}, customEmpresas[0]);

          accumulator.push(customEmpresa);

          return accumulator;
        },
        []
      );

      commit("setEmpresasVinculadasAoUsuario", empresasVinculadasComIdremocao);
      commit(
        "setEmpresasNaoVinculadasAoUsuario",
        empresasNaoVinculadasAoUsuario
      );
    } catch (error) {
      console.error("actionEmpresasParticipantes", error);
      return Promise.reject(new Error(error));
    }
  }
};
