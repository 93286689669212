/* eslint-disable no-unused-vars */
import http from "../../../services/http";
import campanhaService from "../../../services/campanha";
import empresaService from "../../../services/empresa";
import sistema from "@/services/http/sistemaService";

export default {
  actionCampanha: async ({ commit, state }, idCampanha = false) => {
    if (!idCampanha) idCampanha = state.currentCampanha;
    let response = await campanhaService.getById(idCampanha);
    commit("setCampanha", response.data);
  },

  fetchCampanhas: async ({ commit }, filters) => {
    commit("changeRequestStatus", "loading");
    try {
      const { data } = await campanhaService.getAll(filters);
      commit("pushCampanhas", data);
      commit("changeRequestStatus", "success");
    } catch (error) {
      console.error("Action.campanha/fetchCampanhas", error);
      commit("changeRequestStatus", "error");
      return Promise.reject(new Error(error));
    }
  },

  fetchIndicadores: async ({ commit }, params) => {
    try {
      const { data } = await http.get("/sistemas/indicadores", { params });
      commit("setListaIndicadores", data);
    } catch (error) {
      console.error("Action.campanha/fetchIndicadores", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchIndicesCrescimento: async ({ commit }, filters) => {
    try {
      const { data } = await campanhaService.getIndicesCrescimento(filters);
      commit("setListaIndicesCrescimento", data);
    } catch (error) {
      console.error("Action.campanha/fetchIndicesCrescimento", error);
      return Promise.reject(new Error(error));
    }
  },

  sendCampanha: async ({ commit }, payload) => {
    const { idCampanha, form } = payload;

    const campanha = {
      nome_campanha: form.nome,
      tema: form.tema,
      objetivo_campanha: form.objetivo,
      id_tipo_fechamento: form.idTipoFechamento
    };

    try {
      const response = await http({
        method: idCampanha === undefined ? "post" : "put",
        url:
          idCampanha === undefined ? "/campanhas" : `/campanhas/${idCampanha}`,
        data: campanha
      });

      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(error));
    }
  },

  fetchPublicoAlvoCampanha: async ({ commit }, idCampanha) => {
    const { data } = await campanhaService.getFuncoesCampanha(idCampanha);
    const publicoAlvo = data.data.map(funcao => ({
      descricao: funcao.funcao,
      ...funcao
    }));
    commit("setFuncoes", publicoAlvo);
  },

  actionFuncoesPublicoAlvo: async ({ commit }, payload) => {
    // TODO Melhorar estrutura do código
    let {
      data: { data }
    } = await sistema()
      .colaboradorEmpresa()
      .show({
        per_page: -1,
        ativo: "S",
        id_band: payload?.id_band,
        id_empresa: payload?.id_empresa
      });

    let funcoesColab = data.map(item => item.id_funcao);
    funcoesColab = [...new Set(funcoesColab)];
    funcoesColab = funcoesColab.toString();

    let funcoes = await sistema()
      .funcoes()
      .show({
        per_page: -1,
        ativa_campanha: "S",
        ativo: "S",
        id_funcao: funcoesColab
      });

    let publicoAlvo = await campanhaService.getCampanhasPublico(
      payload.id_campanha
    );

    funcoes.data.data.map(funcao => {
      let publico = publicoAlvo.data.data.find(
        publicoSelecionado => funcao.id_funcao == publicoSelecionado.id_funcao
      );

      if (publico != undefined) {
        Object.assign(funcao, {
          selected: true,
          id_campanha_publico: publico.id_campanha_publico
        });
      } else {
        Object.assign(funcao, {
          selected: false
        });
      }
    });

    commit("setFuncoes", funcoes.data.data);
  },

  postMetaProgressiva: payload => {
    console.log("postMetaProgressiva", payload);
  },

  fetchMetasCadastradas: async ({ commit }, idCampanha) => {
    try {
      const { data } = await campanhaService.getFuncoesCampanha(idCampanha);
      commit("setMetasCadastradas", data);
      commit("changeRequestStatus", "success");
      return Promise.resolve();
    } catch (error) {
      console.log("camapanha/fetchMetasCadastradas", error);
      commit("changeRequestStatus", "error");
      return Promise.reject(error);
    }
  },

  fetchFaixasMetasProgressivas: async ({ commit, state }, idCampanha) => {
    try {
      const response = await campanhaService.getMetasProgressivas(idCampanha);
      commit("setFaixasMetaProgressiva", response.data);
    } catch (error) {
      return error;
    }
  },

  actionEmpresasParticipantes: async ({ commit, state }, id_campanha) => {
    try {
      let empresasParticipantes = await campanhaService.getEmpresasParticipantes(
        { id_campanha, per_page: -1 }
      );

      let empresasAtivasCampanhas = await empresaService.getEmpresas({
        ativa_campanha: "S",
        per_page: -1
      });

      empresasAtivasCampanhas.data.data.map(ativasParaCampanha => {
        let participante = empresasParticipantes.data.data.find(
          participante =>
            participante.id_empresa == ativasParaCampanha.id_empresa
        );

        if (participante !== undefined) {
          Object.assign(ativasParaCampanha, {
            selected: true,
            id_empresa_campanha: participante.id_empresa_campanha
          });
        } else {
          Object.assign(ativasParaCampanha, {
            selected: false
          });
        }
      });

      commit("setEmpresas", empresasAtivasCampanhas.data.data);
    } catch (error) {
      console.error("actionEmpresasParticipantes", error);
      return Promise.reject(new Error(error));
    }
  },

  actionEquipesParticipantesCampanha: async ({ commit, state }, element) => {
    try {
      let equipesParticipantes = await campanhaService.getCampanhaEquipe({
        id_campanha: element.id_campanha,
        per_page: -1
      });

      let equipesAtivasCampanhas = await empresaService.getEquipes({
        id_empresa: element.id_empresa,
        ativa_campanha: "S",
        per_page: -1
      });

      equipesAtivasCampanhas.data.data.map(ativasParaCampanha => {
        let participante = equipesParticipantes.data.data.find(
          participante => participante.id_equipe == ativasParaCampanha.id_equipe
        );

        if (participante !== undefined) {
          Object.assign(ativasParaCampanha, {
            selected: true,
            id_campanha_equipe: participante.id_campanha_equipe
          });
        } else {
          Object.assign(ativasParaCampanha, {
            selected: false
          });
        }
      });

      commit("setEquipes", equipesAtivasCampanhas.data.data);
    } catch (error) {
      console.error("actionEmpresasParticipantes", error);
      return Promise.reject(new Error(error));
    }
  },

  sendEmpresasParticipantes: async ({ commit }, payload) => {
    const { idEmpresa, idCampanha } = payload;
    const empresasParticipantes = {
      id_empresa: idEmpresa,
      id_campanha: idCampanha,
      data_cad: "",
      data_alt: "",
      usuario_cad: "",
      usuario_alt: ""
    };

    try {
      const response = await campanhaService.postEmpresasPartipantes(
        empresasParticipantes
      );
      commit("setEmpresasParticipantes", response.data);
      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(error));
    }
  },

  fetchAprovacaoCampanhas: async ({ commit }, filters) => {
    commit("changeRequestStatus", "loading");
    try {
      const { data } = await http.get("/campanhas/aprovacao", {
        params: { ...filters }
      });
      commit("pushAprovacaoCampanhas", data);
      commit("changeRequestStatus", "success");
    } catch (error) {
      console.error("Action.campanha/fetchAprovacaoCampanhas", error);
      commit("changeRequestStatus", "error");
      return Promise.reject(new Error(error));
    }
  },

  resetCampanhaState({ commit }) {
    commit("resetState");
  },

  //Metas
  fetchMetaEmpresa: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasCampanha({ ...filters });
      commit("setMetaEmpresa", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  fetchMetaEquipe: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasCampanha({ ...filters });
      commit("setMetaEquipe", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  fetchMetaIndividual: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasCampanha({ ...filters });
      commit("setMetaIndividual", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  //Realizados
  fetchRealizadoEmpresa: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasRealizado({ ...filters });
      commit("setRealizadoEmpresa", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  fetchRealizadoEquipe: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasRealizado({ ...filters });
      commit("setRealizadoEquipe", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  fetchRealizadoIndividual: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getMetasRealizado({ ...filters });
      commit("setRealizadoIndividual", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    }
  },

  //Apuracao de Campanhas
  actionApuracaoTitle({ commit }, playload) {
    commit("setApuracaoTitle", playload);
  },

  actionApuracaoFilters({ commit }, playload) {
    commit("setApuracaoFilters", playload);
  },
  actionApuracaoDestroy({ commit }) {
    commit("setApuracaoDestroy");
  },

  async actionApuracaoPublicoAlvoCampanha({ commit }, filter) {
    try {
      Object.keys(filter).forEach(
        key => filter[key] == null && delete filter[key]
      );

      let response = await campanhaService.getApuracaoCampanha(filter);
      this.items = response?.data;
      commit("setApuracaoTitle", response?.data[0]?.nome_campanha);
      commit("setApuracaoPublicoAlvoCampanha", response.data);
    } catch (error) {
      console.log("error", error);
      this.$notify({
        text: "Erro ao listar",
        type: "error"
      });
    } finally {
      this.loading = false;
    }
  },

  async actionApuracaoColaboradorCampanha({ commit }, filter) {
    try {
      Object.keys(filter).forEach(
        key => filter[key] == null && delete filter[key]
      );

      let response = await campanhaService.getApuracaoCampanha(filter);
      commit("setApuracaoFilters", filter);
      commit("setApuracaoColaboradorCampanha", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  actionApuracaoCampanha({ commit }, campanha) {
    commit("setApuracaoCampanha", campanha);
  },

  //Campanha Equipe
  fetchEquipesParticipantes: async ({ commit, state }) => {
    try {
      // let idEmpresa = state.empresas?.map(item => item);
      let ativasCampanhas = await empresaService.getEquipes({
        ativo: "S",
        per_page: -1
        // id_empresa: idEmpresa
      });
      let equipesAtivasCampanhas = ativasCampanhas.data.data;

      let participantes = await campanhaService.getCampanhaEquipe({
        id_campanha: state.currentCampanha,
        per_page: -1
      });
      let equipesParticipantes = participantes.data.data;

      const idEquipesParticiparntes = equipesParticipantes.map(
        item => item.id_equipe
      );

      let equipesVinculadasCampanha = equipesAtivasCampanhas.filter(item =>
        idEquipesParticiparntes.includes(item.id_equipe)
      );

      let equipesNaoVinculadasCampanha = equipesAtivasCampanhas.filter(
        item => !idEquipesParticiparntes.includes(item.id_equipe)
      );

      let equipesVinculadasCampanhaPorId = equipesVinculadasCampanha.reduce(
        (accumulator, equipe) => {
          const equipes = participantes.data.data.filter(
            item => item.id_equipe == equipe.id_equipe
          );

          const customEquipes = equipes.map(item => ({
            id_campanha_equipe: item.id_campanha_equipe,
            id_empresa: item.id_empresa,
            id_campanha: item.id_campanha,
            id_indicador: item.id_indicador,
            id_funcao: item.id_funcao,
            id_equipe: item.id_equipe,
            nome_equipe: equipe.nome_equipe,
            meta_campanha: item.meta_campanha,
            meta_gmr: item.meta_gmr,
            perc_cresc: item.perc_cresc
          }));
          let customEquipe = Object.assign({}, customEquipes[0]);

          accumulator.push(customEquipe);

          return accumulator;
        },
        []
      );

      commit("setEquipesVinculadasCampanha", equipesVinculadasCampanhaPorId);
      commit("setEquipesNaoVinculadasCampanha", equipesNaoVinculadasCampanha);
    } catch (error) {
      console.error("fetchEquipesParticipantes", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchCampanhasEquipes: async ({ commit }, filters) => {
    try {
      const response = await campanhaService.getCampanhaEquipe(filters);
      commit("setCampanhasEquipes", response.data);
    } catch (error) {
      console.error("fetchCampanhasEquipes", error);
      return Promise.reject(new Error(error));
    }
  },

  selectedFilters({ commit }, items) {
    commit("setSelectedFilters", items);
  },
  cleanSelectedFilters({ commit }) {
    commit("cleanSelectedFilters");
  }
};
