export default [
  {
    path: "/sistema",
    component: () => import("@/views/sistema/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "processos",
        name: "sistema-processos",
        component: () => import("@/views/sistema/Processos"),
        meta: { requiresAuth: true }
      },
      {
        path: "empresa-processa",
        name: "sistema-empresa-processa",
        component: () => import("@/views/sistema/EmpresaProcessa"),
        meta: { requiresAuth: true }
      },
      {
        path: "parametros-processos",
        component: () =>
          import("@/views/sistema/parametros/ParametrosProcessos/"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-usuarios",
        name: "usuarios",
        component: () => import("@/views/sistema/usuarios/ConsultaUsuarios"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-modulo-menu",
        name: "modulo",
        component: () => import("@/views/sistema/modulos/ConsultaModulo"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-status-consorcio",
        name: "status consorcio",
        component: () =>
          import("@/views/sistema/StatusConsorcio/ConsultaStatusConsorcio"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-status-campanha",
        name: "status campanha",
        component: () =>
          import("@/views/sistema/statusCampanha/ConsultaStatusCampanha"),
        meta: { requiresAuth: true }
      },

      {
        path: "consulta-papel-nivel",
        name: "papel",
        component: () => import("@/views/sistema/papeis/ConsultaPapel"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-permissoes",
        name: "permissoes",
        component: () =>
          import("@/views/sistema/permissoes/ConsultaPermissoes"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
