import rolesDashboard from "../../utils/rolesDashboard";

const dashboard = {
  homeToDashboard: (to, from, next) => {
    if (to.path == "/") next({ path: rolesDashboard.path });
    else next();
  }
};

export default dashboard;
