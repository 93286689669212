import http from "./http";

const campanha = {
  getAll: filters =>
    http.get("/campanhas", {
      params: { ...filters }
    }),

  getBonificacaoMaxima: filter =>
    http.get("/campanhas/tipo-meta-bonus", { params: { ...filter } }),

  getAprovacaoLog: id => http.get(`/campanhas/${id}/aprovacao-log`),

  deleteCampanha: id => {
    return http.delete(`/campanhas/${id}`);
  },

  getIndicadores: filter =>
    http.get("/sistemas/indicadores", { params: { ...filter } }),

  getById: id => http.get(`/campanhas/${id}`),

  getPublicoAlvo: () => http.get("/sistemas/funcoes?ativo=S&ativa_campanha=S"),

  getIndicesCrescimento: filter =>
    http.get("/campanhas/crescimento", { params: { ...filter } }),

  postCampanhaFuncao: form => http.post("/campanhas/funcao", form),

  putCampanha: (id, data) => http.put(`/campanhas/${id}`, data),

  // empresas participantes
  getEmpresasParticipantes: filter =>
    http.get(`/campanhas/empresas/`, { params: { ...filter } }),

  getEmpresaParticipante: idEmpresaParticipante =>
    http.get(`/campanhas/empresas//${idEmpresaParticipante}`),

  postEmpresasPartipantes: data => http.post("/campanhas/empresas/", data),

  deleteEmpresasParticipantes: idEmpresaCampanha =>
    http.delete(`/campanhas/empresas/${idEmpresaCampanha}`),
  // fim empresas participantes

  putCampanhaFuncao: (id_campanha_funcao, form) =>
    http.put(`/campanhas/funcao/${id_campanha_funcao}`, form),

  deleteCampanhaFuncao: idCampanha =>
    http.delete(`/campanhas/funcao/${idCampanha}`),

  getFuncoesCampanha: idCampanha =>
    http.get(`/campanhas/funcao?id_campanha=${idCampanha}&per_page=-1`),

  getCampanhaFuncao: filters => {
    return http.get("/campanhas/funcao", {
      params: { ...filters }
    });
  },

  getFuncoes: idBandeira =>
    http.get("/sistemas/funcoes?ativo=S&ativa_campanha=S", {
      params: {
        id_band: idBandeira,
        per_page: -1
      }
    }),

  getMetasProgressivas: idCampanha =>
    http.get("/campanhas/meta-progressiva", {
      params: { id_campanha: idCampanha }
    }),

  postMetaProgressiva: data => http.post("/campanhas/meta-progressiva", data),
  deleteMetaProgressiva: id_meta_progressiva =>
    http.delete(`/campanhas/meta-progressiva/${id_meta_progressiva}`),
  // companhas publico
  getCampanhasPublico: id_campanha =>
    http.get(`/campanhas/publico`, { params: { id_campanha, per_page: -1 } }),

  postCampanhaPublico: form => http.post("/campanhas/publico", form),

  deleteCampanhaPublico: idCampanhaPublico =>
    http.delete(`/campanhas/publico/${idCampanhaPublico}`),
  // fim companhas publico

  //apuração campanha
  getApuracaoCampanha: filter =>
    http.get("/campanhas/apuracao/calculo", { params: { ...filter } }),

  getApuracaoCampanhasAll: filter =>
    http.get("/campanhas/apuracao", { params: { ...filter } }),

  //Aprovação de campanha
  getAprovacaoCampanhas: filter =>
    http.get("/campanhas/aprovacao", { params: { ...filter } }),

  aprovacaoCampanha: (id_campanha, id_status, observacao) =>
    http.post("/campanhas/aprovacao", {
      id_campanha,
      id_status,
      observacao
    }),

  AtualizaAprovacaoCampanha: (
    id_aprovacao,
    id_status,
    id_usuario,
    observacao
  ) =>
    http.put(`/campanhas/aprovacao/${id_aprovacao}`, {
      id_status,
      id_usuario,
      observacao
    }),

  getMetasCampanha: filters =>
    http.get("/campanhas/metas", {
      params: { ...filters }
    }),

  // Relatorios XLS
  getMetasCampanhaXls: filters =>
    http.get("/campanhas/metas/xls", {
      responseType: "arraybuffer",
      params: { ...filters }
    }),

  getRealizadosCampanhaXls: filters =>
    http.get("/campanhas/realizados/xls", {
      responseType: "arraybuffer",
      params: { ...filters }
    }),

  getApuracaoGeralCampanhaXls: filters =>
    http.get("/campanhas/apuracao/xls", {
      responseType: "arraybuffer",
      params: { ...filters }
    }),

  getApuracaoInfiniteCampanhaXls: filters =>
    http.get("/campanhas/apuracao/xls-infinity", {
      responseType: "arraybuffer",
      params: { ...filters }
    }),

  getConsultaCampanhaXls: filters =>
    http.get("/campanhas/xls", {
      responseType: "arraybuffer",
      params: { ...filters }
    }),

  getMetasRealizado: filters =>
    http.get("/campanhas/realizados", {
      params: { ...filters }
    }),

  getMetasAgrupadas: filters =>
    http.get("/campanhas/metas/equipes", {
      params: { ...filters }
    }),

  postCampanha: async data => {
    return await http.post("/campanhas", data);
  },

  //Campanhas Equipes
  getCampanhaEquipe: filters => {
    return http.get("/campanhas/equipes", {
      params: { ...filters }
    });
  },
  postCampanhaEquipe: data => {
    return http.post("/campanhas/equipes", data);
  },
  putCampanhaEquipe: (id, data) => {
    return http.put(`/campanhas/equipes/${id}`, data);
  },
  deleteCampanhaEquipe: id => {
    return http.delete(`/campanhas/equipes/${id}`);
  },

  //Colaboradores Campanha
  getColaboradoresCampanha: filters =>
    http.get("/campanhas/colaborador", { params: filters }),

  postColaboradoresCampanha: data => http.post("/campanhas/colaborador", data),

  deleteColaboradoresCampanha: id =>
    http.delete(`/campanhas/colaborador/${id}`),

  // Lista logs de processos da campanha
  getLogsProcessosCampanha: id => http.get(`/campanhas/${id}/processos-logs/`),
  getLosProcessos: filters =>
    http.get(`/campanhas/process-logs`, { params: { ...filters } }),

  getRealizadosEquipe: filters =>
    http.get("/campanhas/realizados/equipes", { params: { ...filters } }),

  // Aprovação de pagamento
  showAprovacaoDePagamentoCampanha: id =>
    http.get(`/campanhas/${id}/aprovacao-pagamento`),

  postAprovacaoDePagamentoCampanha: (id, body) =>
    http.post(`/campanhas/${id}/aprovacao-pagamento`, body),

  getStatusCampanha: () => {
    return [
      {
        text: "Análise",
        id_status_camp: "2",
        icon: "mdi-timer-sand",
        color: "warning"
      },
      {
        text: "Andamento",
        id_status_camp: "21",
        icon: "mdi-progress-clock",
        color: "success"
      },
      {
        text: "Aprovada",
        id_status_camp: "1",
        icon: "mdi-thumb-up-outline",
        color: "primary"
      },
      {
        text: "Expirada",
        id_status_camp: "23",
        icon: "mdi-timer-sand-full",
        color: "error"
      },
      {
        text: "Finalizada",
        id_status_camp: "22",
        icon: "mdi-check-all",
        color: "info"
      },
      {
        text: "Reprovado",
        id_status_camp: "3",
        icon: "mdi-thumb-down-outline",
        color: "red"
      },
      {
        text: "Indefinido",
        id_status_camp: "24",
        icon: "mdi-help-circle-outline",
        color: "secundary"
      }
    ];
  },

  // Campanha Modelo
  getCampanhaModelo: filters => {
    return http.get("/campanhas/modelo", {
      params: { ...filters }
    });
  },

  postCampanhaModelo: data => {
    return http.post("/campanhas/modelo", data);
  },

  deleteCampanhaModelo: id => {
    return http.delete(`/campanhas/modelo/${id}`);
  }
  // Campanha Modelo
};

export default campanha;
