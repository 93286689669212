import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import store from "../store";
import router from "../router";

// import paramInterceptors from "./paramInterceptors/index";
const http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 120000,
  headers: { "Content-Type": "application/json" },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: "useCache"
  })
});

http.interceptors.request.use(
  config => {
    // config.params = paramInterceptors(config);

    const token = store.getters.getToken;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        store.dispatch("logout");
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
);

export default http;
