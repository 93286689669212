export default {
  users: [],
  permissions: [],
  userPermissions: [],
  empresasVinculadasAoUsuario: [],
  empresasNaoVinculadasAoUsuario: [],
  views: {},
  hiddenValue: {
    status: ""
  }
};
