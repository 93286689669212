import http from "./http";
import axios from "axios";

const login = {
  tryLogin: (email, password) => http.post("/auth/login", { email, password }),
  confirmCadastro: token =>
    axios.post(
      process.env.VUE_APP_ROOT_API + "/sistemas/usuarios/confirm",
      token
    ),
  resendConfirmationRegistration: form => {
    return axios.post(
      process.env.VUE_APP_ROOT_API +
        "/sistemas/usuarios/reenvia-confimacao-cadastro",
      form
    );
  },
  sendPasswordRestRequest: form => {
    return axios.post(
      process.env.VUE_APP_ROOT_API +
        "/sistemas/usuarios/solicita-redefinicao-senha",
      form
    );
  },
  confirmResetPassword: form => {
    return axios.post(
      process.env.VUE_APP_ROOT_API +
        "/sistemas/usuarios/confirma-redefinicao-senha",
      form
    );
  }
};

export default login;
