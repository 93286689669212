import http from "./http";

const comissao = {
  getTiposFechamento: filters =>
    http.get("/comissoes/tipo-fechamento", { params: { ...filters } }),

  // Tipo dados
  getTiposDados: filters =>
    http.get("comissoes/tipo-dados", { params: { ...filters } }),

  postTipoDado: async data => {
    return await http.post("comissoes/tipo-dados", data);
  },

  putTipoDado: async (id, data) => {
    return await http.put(`comissoes/tipo-dados/${id}`, data);
  },
  // Tipo dados

  getGrupoComissao: filters =>
    http.get("/comissoes/comissoes-grupo", { params: { ...filters } }),

  // Tipos evento
  getTiposEvento: filters =>
    http.get("/comissoes/tipo-evento", { params: { ...filters } }),

  // Tipos eventos

  postPeriodoFechamento: async data => {
    return await http.post("/comissoes/tipo-fechamento", data);
  },

  putPeriodoFechamento: async (data, id) => {
    return await http.put(`/comissoes/tipo-fechamento/${id}`, data);
  },

  deletePeriodoFechamento: async id => {
    return await http.delete(`/comissoes/tipo-fechamento/${id}`);
  }
};

export default comissao;
