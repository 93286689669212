import http from "./http";
// import store from "../store";

let empresas = [
  { id_band: 2, empresas: [] },
  { id_band: 4, empresas: [] },
  { id_band: 5, empresas: [] },
  { id_band: 6, empresas: [] },
  { id_band: 7, empresas: [] }
];

const empresa = {
  //Empresa
  getEmpresas: async filters => {
    let result = [];
    let res = await http.get("/sistemas/empresas", { params: { ...filters } });

    empresas.map((one_company, index) => {
      empresas[index].empresas = res.data.data
        .filter(item => item.id_band == one_company.id_band)
        .sort((a, b) => (a.apelido > b.apelido ? 1 : -1));
      result = result.concat([], one_company.empresas);
    });
    res.data.data = result;
    return res;
  },

  createEmpresa(data) {
    return http.post(`/sistemas/empresas`, data);
  },

  //Matriz
  getEmpresasMatrizes: filters => {
    return http.get("/sistemas/empresas-matrizes", { params: { ...filters } });
  },

  //Cargos
  getCargos: filters => {
    return http.get("/comissoes/cargos", { params: { ...filters } });
  },
  postCargos: data => {
    return http.post("/comissoes/cargos", data);
  },
  putCargos: (id, data) => {
    return http.put(`/comissoes/cargos/${id}`, data);
  },
  deleteCargos: id => {
    return http.delete(`/comissoes/cargos/${id}`);
  },

  //Funções
  getFuncoes: filters => {
    return http.get("/sistemas/funcoes", { params: { ...filters } });
  },
  postFuncoes: data => {
    return http.post("/sistemas/funcoes", data);
  },
  putFuncoes: (id, data) => {
    return http.put(`/sistemas/funcoes/${id}`, data);
  },
  deleteFuncoes: id => {
    return http.delete(`/sistemas/funcoes/${id}`);
  },

  //Bandeira
  getBandeiras: filters => {
    return http.get("/sistemas/tipos-bandeiras", { params: { ...filters } });
  },

  //Departamento
  getDepartamento: filters => {
    return http.get("/comissoes/departamentos", { params: { ...filters } });
  },
  postDepartamento: data => {
    return http.post("/comissoes/departamentos", data);
  },
  putDepartamento: (id, data) => {
    return http.put(`/comissoes/departamentos/${id}`, data);
  },
  deleteDepartamento: id => {
    return http.delete(`/comissoes/departamentos/${id}`);
  },

  //Regioes
  getRegioes: filters => {
    return http.get("/sistemas/regioes", { params: { ...filters } });
  },

  //Grupo Empresa
  getGrupoEmpresa: filters => {
    return http.get("/sistemas/empresas-grupo", { params: { ...filters } });
  },

  //Tipos Equipes
  getEquipes: filters => {
    return http.get("/comissoes/tipos-equipes", {
      params: { ...filters }
    });
  },
  postEquipes: data => {
    return http.post("/comissoes/tipos-equipes", data);
  },
  putEquipes: (id, data) => {
    return http.put(`/comissoes/tipos-equipes/${id}`, data);
  },
  deleteEquipes: id => {
    return http.get(`/comissoes/tipos-equipes/${id}`);
  }
};

export default empresa;
