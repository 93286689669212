export default [
  {
    path: "campanhas",
    component: () => import("@/views/campanha/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "dashboard-campanha",
        component: () => import("@/views/campanha/dashboard/Dashboard"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta",
        name: "consulta-campanha",
        component: () => import("@/views/campanha/ConsultaCampanha"),
        meta: { requiresAuth: true }
      },
      {
        path: "cadastro",
        name: "cadastro-campanha",
        component: () => import("@/views/campanha/CadastroCampanha"),
        meta: { requiresAuth: true },
        children: [
          {
            path: ":idCampanha?",
            name: "cadastro-capa-campanha",
            component: () =>
              import(
                "../../../components/campanha/cadastro/capa/FormCadastroCampanha"
              ),
            meta: {
              requiresAuth: true,
              step: 1,
              backStep: false,
              nextStep: false,
              validBtnNextStep: false
            }
          },
          {
            path: ":idCampanha/empresas",
            name: "empresas",
            component: () =>
              import(
                "../../../components/campanha/cadastro/empresas/DraggableEmpresasCampanha"
              ),
            meta: {
              requiresAuth: true,
              step: 2,
              backStep: true,
              nextStep: "publico-alvo",
              validBtnNextStep: "getStatusEmpresa"
            }
          },
          {
            path: ":idCampanha/publico-alvo",
            name: "publico-alvo",
            component: () =>
              import(
                "../../../components/campanha/cadastro/publicoAlvo/FormPublicoAlvo"
              ),
            meta: {
              requiresAuth: true,
              step: 3,
              backStep: true,
              nextStep: "publico-meta",
              validBtnNextStep: "getStatusPublicoAlvo"
            }
          },
          {
            path: ":idCampanha/publico-meta",
            name: "publico-meta",
            component: () =>
              import(
                "../../../components/campanha/cadastro/PublicoMeta/SectionPublicoMeta"
              ),
            meta: {
              requiresAuth: true,
              step: 4,
              backStep: true,
              nextStep: "resumo",
              validBtnNextStep: "getStatusMetas"
            }
          },
          {
            path: ":idCampanha/resumo",
            name: "resumo",
            component: () =>
              import(
                "../../../components/campanha/cadastro/resumo/ResumoCadastroCampanha"
              ),
            meta: {
              requiresAuth: true,
              step: 5,
              backStep: true,
              nextStep: false,
              validBtnNextStep: false
            }
          }
        ]
      },
      {
        path: "aprovacao",
        name: "",
        component: () => import("@/views/campanha/aprovacao/Index"),
        children: [
          {
            path: "",
            name: "aprovacao-campanha",
            component: () =>
              import("@/views/campanha/aprovacao/AprovacaoCampanha"),
            meta: { requiresAuth: true }
          },
          {
            path: ":idCampanha",
            name: "avaliacao-campanha",
            component: () =>
              import("@/views/campanha/aprovacao/AvaliacaoCampanha"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: "lista-metas",
        name: "",
        component: () => import("@/views/campanha/metas/Index"),
        children: [
          {
            path: "",
            name: "metas-campanha",
            component: () => import("@/views/campanha/metas/MetasCampanha"),
            meta: { requiresAuth: true }
          },
          {
            path: "meta",
            name: "tipos-metas",
            component: () => import("@/views/campanha/metas/TiposMetas"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: "lista-realizados",
        component: () => import("@/views/campanha/realizado/Index"),
        children: [
          {
            path: "",
            name: "lista-campanhas",
            component: () => import("@/views/campanha/realizado/MetaRealizado"),
            meta: { requiresAuth: true }
          },
          {
            path: "realizado",
            name: "lista-metas",
            component: () => import("@/views/campanha/realizado/ListaMetas"),
            meta: { requiresAuth: true }
          }
        ],
        meta: { requiresAuth: true }
      },
      {
        path: "apuracao",
        component: () => import("@/views/campanha/apuracao/Index"),
        children: [
          {
            path: "/",
            name: "apuracao-campanha",
            component: () =>
              import("@/views/campanha/apuracao/ApuracaoCampanha"),
            meta: { requiresAuth: true }
          },
          {
            path: ":idCampanha",
            name: "apuracao-campanha-empresa",
            component: () =>
              import("@/views/campanha/apuracao/DetalhesCampanha"),
            meta: { requiresAuth: true }
          }
        ],
        meta: { requiresAuth: true }
      },
      {
        path: "processamento",
        component: () => import("@/views/campanha/processamento/Index"),
        children: [
          {
            path: "",
            component: () =>
              import("@/views/campanha/processamento/ProcessamentoCampanha"),
            meta: { requiresAuth: true }
          }
        ]
      }
    ]
  }
];
