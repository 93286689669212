import http from "./http";

const sistema = {
  //Usuarios
  getUsers: filters => {
    return http.get("/sistemas/usuarios", { params: { ...filters } });
  },
  postUsers: data => {
    return http.post("/sistemas/usuarios", data);
  },
  putUsers: (id, data) => {
    return http.put(`/sistemas/usuarios/${id}`, data);
  },
  deleteUsers: id => {
    return http.get(`/sistemas/usuarios/${id}`);
  },

  //Modulos
  getModulo: filters => {
    return http.get("/sistemas/modulos", { params: { ...filters } });
  },
  postModulo: data => {
    return http.post("/sistemas/modulos", data);
  },
  putModulo: (id, data) => {
    return http.put(`/sistemas/modulos/${id}`, data);
  },
  deleteModulo: id => {
    return http.get(`/sistemas/modulos/${id}`);
  },

  //Menus
  getMenu: filters => {
    return http.get("/sistemas/menus", { params: { ...filters } });
  },
  postMenu: data => {
    return http.post("/sistemas/menus", data);
  },
  putMenu: (id, data) => {
    return http.put(`/sistemas/menus/${id}`, data);
  },
  deleteMenu: id => {
    return http.get(`/sistemas/menus/${id}`);
  },

  //Permissoes

  getPermissoes: filters => {
    return http.get("/sistemas/permissoes", { params: { ...filters } });
  },
  postPermissoes: data => {
    return http.post("/sistemas/permissoes", data);
  },
  putPermissoes: (id, data) => {
    return http.put(`/sistemas/permissoes/${id}`, data);
  },
  deletePermissoes: id => {
    return http.get(`/sistemas/permissoes/${id}`);
  },

  //usuário /Permissoes

  getUserPermissoes: id => {
    return http.get(`/sistemas/usuarios/${id}/permissoes`);
  },

  postUserPermissoes: (id, data) =>
    http.post(`/sistemas/usuarios/${id}/permissoes`, data),

  //Empresas vinculadas a usuários

  getUserEmpresas: filters => {
    return http.get("/sistemas/usuarios-empresas", {
      params: { ...filters }
    });
  },
  postUserEmpresas: data => {
    return http.post("/sistemas/usuarios-empresas", data);
  },
  deletUserEmpresas: id => {
    return http.delete(`/sistemas/usuarios-empresas/${id}`);
  },

  //Niveis

  getNiveis: filters => {
    return http.get("/sistemas/niveis", { params: { ...filters } });
  },
  postNivel: data => {
    return http.post("/sistemas/niveis", data);
  },
  putNivel: (id, data) => {
    return http.put(`/sistemas/niveis/${id}`, data);
  },
  deleteNivel: id => {
    return http.get(`/sistemas/niveis/${id}`);
  },

  //Papeis

  getPapeis: filters => {
    return http.get("/sistemas/papeis", { params: { ...filters } });
  },
  postPapel: data => {
    return http.post("/sistemas/papeis", data);
  },
  putPapel: (id, data) => {
    return http.put(`/sistemas/papeis/${id}`, data);
  },
  deletePapel: id => {
    return http.get(`/sistemas/papeis/${id}`);
  }
};

export default sistema;
