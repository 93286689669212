// import campanha from "./campanha";
// import colaboradores from "./colaboradores";
// import comissao from "./comissao";
// import empresas from "./empresas";
// import exemplo from "./exemplo";
// import parametros from "./parametros";
// import perfil from "./perfil";
// import sistema from "./sistema";
import dashboard from "../../guards/dashboard";

const requireRoutes = require.context(".", true, /\.js$/);
const routes = [];

requireRoutes.keys().forEach(fileName => {
  if (fileName === "./index.js") return;

  const allRoutesFiles = requireRoutes(fileName).default;
  routes.push(...allRoutesFiles);
});

export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/components/shared/layout/Index"),
    children: [...routes],
    beforeEnter: (to, from, next) => dashboard.homeToDashboard(to, from, next)
  },
  {
    path: "*",
    name: "404",
    redirect: {
      name: "dashboard-campanha"
    }
  }
];
