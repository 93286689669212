export default [
  {
    path: "/login",
    component: () => import("@/views/campanha/Index"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/auth/Login")
      }
    ]
  },
  {
    path: "/cadastro",
    component: () => import("@/views/user/Index"),
    children: [
      {
        path: "",
        name: "cadastro-usuario",
        component: () => import("@/components/user/Cadastro")
      },
      {
        path: "confirmacao/:token",
        name: "confirmacao",
        component: () => import("@/components/user/Confirmacao")
      },
      {
        path: "reenviar-confirmacao",
        name: "reenviar-confirmacao",
        component: () => import("@/components/user/ReenviarConfirmacaoCadastro")
      },
      {
        path: "redefinicao-senha/:token",
        name: "redefinicao-senha",
        component: () => import("@/components/user/RedefineSenha")
      },
      //teste
      {
        path: "solicita-redefinicao-senha",
        name: "solicita-redefinicao-senha",
        component: () => import("@/components/user/SolicitaRedefinicaoSenha")
      }
    ]
  }
];
