import login from "../../services/login";

export default {
  state: {
    token: window.localStorage.getItem("token") || "",
    user: JSON.parse(window.localStorage.getItem("user")) || {}
  },
  mutations: {
    setToken(state, token) {
      window.localStorage.setItem("token", token);
      state.token = token;
    },

    setUser(state, user) {
      window.localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },

    setPermission(state, permission) {
      window.localStorage.setItem("permission", permission);
      state.permission = permission;
    }
  },

  actions: {
    async tryLogin({ commit }, payload) {
      const { data } = await login.tryLogin(payload.email, payload.password);
      commit("setToken", data.access_token);
      commit("setUser", data.user);
    },

    logout({ commit }) {
      commit("setToken", "");
      commit("setUser", {});
    }
  },

  getters: {
    isLogged: state => {
      if (state.token == "") {
        return false;
      } else return true;
    },

    getToken: state => state.token,
    getUser: state => state.user,
    getEmpresas: state => filters => {
      const empresas = state.user.empresas.toString();
      const idIncludes = Object.keys(filters).includes("id_empresa");

      if (!idIncludes || filters?.id_empresa == undefined)
        return { ...filters, id_empresa: empresas };
      return { ...filters };
    },
    getUserId: state => filters => {
      const user = state.user.id;
      const idIncludes = Object.keys(filters).includes("id_usuario");

      if (!idIncludes || filters?.id_usuario == undefined)
        return { ...filters, id_usuario: user };

      return { ...filters };
    }
  }
};
