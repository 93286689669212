const prefix = "Dashboard";
const allowedRolesDashboard = ["Gerente", "Diretoria", "Administrador"];
const campaignDashboardPath = "/dashboard";
const commissionDashboardPath = "/comissao/dashboard";

const mapRoleDashboard = [
  {
    nivel: "Colaborador",
    component: `${prefix}Colaborador`
  },
  {
    nivel: "Gerente",
    component: `${prefix}Gerente`
  },
  {
    nivel: "Administrador",
    component: `${prefix}Gerente`
  },
  {
    nivel: "Diretoria",
    component: `${prefix}Gerente`
  },
  {
    nivel: "Supervisor",
    component: `${prefix}Supervisor`
  }
];

const getRotesComponent = rule => {
  let currentDashboard =
    mapRoleDashboard.find(_rule => _rule.nivel === rule?.nome)?.component ||
    mapRoleDashboard[0].component;

  let path = allowedRolesDashboard.includes(rule?.nome)
    ? campaignDashboardPath
    : commissionDashboardPath;
  return { path, currentDashboard };
};

const currentUser = () => {
  let userLocalStorage = localStorage.getItem("user");
  if (userLocalStorage == "{}" || userLocalStorage == null) {
    return getRotesComponent({});
  }

  let { rule } = JSON.parse(userLocalStorage);

  return getRotesComponent(rule);
};

export default currentUser();
