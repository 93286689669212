export default [
  {
    path: "/empresas",
    component: () => import("@/views/campanha/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "cadastro",
        name: "cadastro-empresas",
        component: () => import("@/views/empresa/CadastroEmpresas"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-empresas",
        name: "consulta-empresas",
        component: () => import("@/views/empresa/ConsultaEmpresas"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-bandeira",
        name: "bandeiras",
        component: () => import("@/views/empresa/ConsultaBandeira"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-departamento",
        name: "departamento",
        component: () => import("@/views/empresa/ConsultaDepartamento"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-equipe",
        name: "equipe",
        component: () => import("@/views/empresa/ConsultaEquipe"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-regiao",
        name: "regioes",
        component: () => import("@/views/empresa/ConsultaRegiao"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-grupo-comissao",
        name: "grupo-comissao",
        component: () => import("@/views/empresa/ConsultaGrupoComissao"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-grupo-empresas",
        name: "grupo-empresa",
        component: () => import("@/views/empresa/ConsultaGrupoEmpresa"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
