export default {
  getCampanha: state => {
    return state.campanha;
  },

  getCampanhas: state => {
    if (state.list.data) {
      return state.list.data;
    }
    return [];
  },

  getAprovacaoCampanhas: state => {
    if (state.listAprovacoesCampanha) {
      return state.listAprovacoesCampanha;
    }

    return [];
  },

  getLoadingState: state => {
    if (state.requestStatus == "loading") {
      return true;
    }
    return false;
  },

  getPublicoAlvoSelecionado: (state, getters) => {
    return state.funcoes.filter(funcao => {
      let status = getters.getMetasCadastradas?.some(meta => {
        return funcao.id_funcao == meta.id_funcao;
      });
      Object.assign(funcao, { possuiMeta: status });
      return funcao.selected == true;
    });
  },

  getFuncoes: state => {
    return state.funcoes;
  },

  getFuncaoCampanhaProgressiva: state => {
    const metas = state.metasCadastradas.data;

    if (metas) {
      const metasProgressivas = metas.filter(item => item.id_tipo_meta == 5);
      return metasProgressivas;
    }
    return [];
  },

  getMetasCadastradas: state => {
    if (state.metasCadastradas) {
      return state.metasCadastradas.data;
    }
    return [];
  },

  getCurrentCampanha: state => {
    return state.currentCampanha;
  },

  getIndicadores: state => {
    if (state.indicadores.data) {
      return state.indicadores.data;
    }
    return [];
  },

  getIndicesCrescimento: state => {
    if (state.indicesCrescimento.data) {
      // TODO criar um helper para ordenação genérico
      return state.indicesCrescimento.data.sort((a, b) => {
        a = parseInt(a.perc_cresc, 10);
        b = parseInt(b.perc_cresc, 10);

        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    }
    return [];
  },

  getFaixasMetaProgressiva: state => idCampanhaFuncao => {
    if (state.metaProgressiva.faixas) {
      let faixas = state.metaProgressiva.faixas.data;
      faixas = faixas.filter(
        meta => meta.id_campanha_funcao == idCampanhaFuncao
      );

      return faixas.sort((a, b) => {
        a = parseFloat(a.valor_bonus);
        b = parseFloat(b.valor_bonus);

        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    }
    return [];
  },

  getEmpresasNaoParticipantes: state => {
    return state.empresas.filter(empresa => empresa.selected == false);
  },

  getEmpresasParticipantes: state => {
    return state.empresas.filter(empresa => empresa.selected == true);
  },

  // validação de formulario da campanha
  getStatusEmpresa: (state, getters) => {
    return !!getters.getEmpresasParticipantes.length;
  },

  getStatusPublicoAlvo: (state, getters) => {
    return !!getters.getPublicoAlvoSelecionado.length;
  },

  getStatusMetas: (state, getters) => {
    let status = true;
    status = getters.getPublicoAlvoSelecionado?.some(publico => {
      let status = getters.getMetasCadastradas?.some(meta => {
        return publico.id_funcao == meta.id_funcao;
      });
      return !status;
    });

    return !status;
  },

  getValidBtnNextStepForm: state => {
    return state.validBtnNextStepFrom;
  },

  getStatusMetasPrograssivas: (state, getters) => {
    if (!getters.getFuncaoCampanhaProgressiva.length) return true;

    return getters.getFuncaoCampanhaProgressiva?.some(progressiva => {
      let status = getters.getFaixasMetaProgressiva(
        progressiva.id_campanha_funcao
      ).length;
      return status >= 2;
    });
  },

  //Metas
  getMetaEmpresa: state => {
    return state.metas.metaEmpresa;
  },

  getMetaEquipe: state => {
    return state.metas.metaEquipe;
  },

  getMetaIndividual: state => {
    return state.metas.metaIndividual;
  },

  //Realizados
  getRealizadoEmpresa: state => {
    return state.realizados.realizadoEmpresa;
  },

  getRealizadoEquipe: state => {
    return state.realizados.realizadoEquipe;
  },

  getRealizadoIndividual: state => {
    return state.realizados.realizadoIndividual;
  },

  // Apuração de campanha
  getApuracao: state => state.apuracao,
  getApuracaoQtEmpresa: state => {
    let qt = state.apuracao.itemsPublicoAlvoCampanha.reduce(
      (retorno, element) => {
        retorno[element.apelido] = "";
        return retorno;
      },
      {}
    );

    return Object.keys(qt);
  },

  getApuracaoQtColaboradorMeta: state => {
    return state.apuracao.itemsColaboradorCampanha.reduce(
      (retorno, element) => {
        if (Object.keys(retorno).includes(element.ganhou)) {
          retorno[element.ganhou]++;
        } else {
          retorno[element.ganhou] = 1;
        }
        return retorno;
      },
      {}
    );
  },

  getApuracaoQtIndicador: state => {
    const campanhaIndicador = state.apuracao.itemsPublicoAlvoCampanha.reduce(
      (retorno, element) => {
        if (Object.keys(retorno).includes(element.id_indicador)) {
          retorno[element.id_indicador]++;
        } else {
          retorno[element.id_indicador] = 1;
        }
        return retorno;
      },
      {}
    );

    return Object.values(campanhaIndicador).length;
  },

  getApuracaoQtPublico: state => {
    const campanhaPublico = state.apuracao.itemsPublicoAlvoCampanha.reduce(
      (retorno, element) => {
        if (Object.keys(retorno).includes(element.id_funcao)) {
          retorno[element.id_funcao]++;
        } else {
          retorno[element.id_funcao] = 1;
        }
        return retorno;
      },
      {}
    );

    return Object.values(campanhaPublico).length;
  },

  getResultadoTotal: state => {
    let total = 0;
    state.apuracao.itemsColaboradorCampanha.map(item => {
      total += parseFloat(item.valor_result);
    });

    return total;
  },

  getResultadoTotalEstimado: state => {
    let total = 0;
    state.apuracao.itemsColaboradorCampanha.map(item => {
      total += parseFloat(item.valor_result_estimado);
    });

    return total;
  },

  getApuracaoCrecimentoGeral: state => {
    let crescimentoGeralArray = Object.keys(state.apuracao.itemsColaboradorCampanha.reduce((result, currentValue) => {
      (result[currentValue['cresc_geral']] = result[currentValue['cresc_geral']] || []).push(
        currentValue
      );
      return result;
    }, {}))

    let crescimentoValor = 0
    crescimentoGeralArray.forEach(item => {
      crescimentoValor += parseFloat(item)
    })

    return (crescimentoValor / crescimentoGeralArray.length).toFixed(2);
  },

   //Campanha Equipe
  getCampanhasEquipes: state => {
    if (state.campanhasEquipes) {
      return state.campanhasEquipes.data;
    }
    return [];
  },

  getEquipesNaoParticipantes: state => {
    return state.equipes.filter(equipe => equipe.selected == false);
  },

  getEquipesParticipantes: state => {
    return state.equipes.filter(equipe => equipe.selected == true);
  },

  getSelectedFilters: state => {
    return state.selected_filters;
  }
};
