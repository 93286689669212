export default {
  pushEmpresas(state, empresas) {
    state.list = empresas;
  },
  setEmpresas(state, empresas) {
    state.list = empresas;
  },
  changeRequestStatus(state, status) {
    state.requestStatus = status;
  },

  setFormEmpresa(state, payload) {
    Object.assign(state.form, payload);
  },

  setEquipes(state, equipes) {
    state.equipes = equipes;
  }
};
