export default {
  setUser(state, user) {
    state.users = user;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },

  setUserPermissions(state, userPermissions) {
    state.userPermissions = userPermissions;
  },

  setEmpresasVinculadasAoUsuario(state, empresasVinculadasAoUsuario) {
    state.empresasVinculadasAoUsuario = empresasVinculadasAoUsuario;
  },
  setEmpresasNaoVinculadasAoUsuario(state, empresasNaoVinculadasAoUsuario) {
    state.empresasNaoVinculadasAoUsuario = empresasNaoVinculadasAoUsuario;
  },

  setView(state, view) {
    state.views.name = view;
  },

  setHiddenValue(state, status) {
    state.hiddenValue.status = status;
  }
};
