import comissaoService from "../../../services/comissao";
import http from "../../../services/http";

export default {
  fetchTiposFechamento: async ({ commit }, filter) => {
    try {
      const { data } = await comissaoService.getTiposFechamento({ ...filter });
      commit("setTiposFechamento", data);
    } catch (error) {
      console.error("Action.comissao/fetchTiposFechamento", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchTiposDados: async ({ commit }, filters) => {
    try {
      const { data } = await comissaoService.getTiposDados({ ...filters });
      commit("setTiposDados", data);
    } catch (error) {
      console.error("Action.comissao/fetchTiposDados", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchProcessamento: async ({ commit }, filters) => {
    try {
      const response = await http.get("/comissoes/procedures/processos", {
        params: { ...filters }
      });
      commit("setProcessamento", response.data);
    } catch (error) {
      console.error("Action.comissao/fetchProcessamento", error);
      return Promise.reject(new Error(error));
    }
  },

  fetchTiposEvento: async ({ commit }) => {
    try {
      const { data } = await comissaoService.getTiposEvento();
      commit("setTiposEvento", data);
    } catch (error) {
      console.error("Action.comissao/fetchTiposEvento", error);
      return Promise.reject(new Error(error));
    }
  },

  postPeriodoFechamento: async ({ dispatch }, payload) => {
    try {
      await comissaoService.postPeriodoFechamento(payload);
      await dispatch("fetchTiposFechamento");
    } catch (error) {
      console.error("Action.comissao/fetchTiposEvento", error);
      return Promise.reject(error);
    }
  },

  putPeriodoFechamento: async ({ dispatch }, { payload, id, filters }) => {
    try {
      await comissaoService.putPeriodoFechamento(payload, id);
      await dispatch("fetchTiposFechamento", filters);
    } catch (error) {
      console.error("Action.comissao/fetchTiposEvento", error);
      return Promise.reject(error);
    }
  },

  deletePeriodoFechamento: async ({ dispatch }, { id, filters }) => {
    try {
      await comissaoService.deletePeriodoFechamento(id);
      await dispatch("fetchTiposFechamento", filters);
    } catch (error) {
      console.error("Action.comissao/fetchTiposEvento", error);
      return Promise.reject(error);
    }
  }
};
