export default {
  list: [],
  listAprovacoesCampanha: [],
  requestStatus: "",
  currentCampanha: "",
  funcoes: [],
  campanha: {},
  empresas: [],
  indicadores: [],
  indicesCrescimento: [],
  tipoDados: [],
  metasCadastradas: [],
  metaProgressiva: {
    loading: false,
    faixas: {
      data: []
    }
  },

  //Metas
  metas: {
    metaEmpresa: [],
    metaEquipe: [],
    metaIndividual: []
  },

  //Realizados
  realizados: {
    realizadoEmpresa: [],
    realizadoEquipe: [],
    realizadoIndividual: []
  },

  //Apuração
  apuracao: {
    title: "Apuração de Campanha",
    campanha: {},
    filters: {
      id_empresa: null,
      id_tipo_meta: null,
      id_campanha_funcao: null,
      id_funcao: null
    },
    itemsPublicoAlvoCampanha: [],
    itemsColaboradorCampanha: []
  },

  //Campanha Equipe
  equipes: [],
  campanhasEquipes: [],
  validBtnNextStepFrom: true,

  selected_filters: {}
};
