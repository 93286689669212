export default [
  {
    path: "/exemplo",
    component: () => import("@/views/exemplo/Index"),
    children: [
      {
        path: "",
        name: "exemplo1",
        component: () => import("@/views/exemplo/Exemplo1"),
        meta: { requiresAuth: true }
      },
      {
        path: "/exemplo2",
        name: "exemplo2",
        component: () => import("@/views/exemplo/Exemplo2"),
        meta: { requiresAuth: true }
      },
      {
        path: "/exemplo3",
        name: "exemplo3",
        component: () => import("@/views/exemplo/Exemplo3"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
