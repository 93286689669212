<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1000">
      <h1>testeeeeee</h1>
      <v-img :src="require('@/assets/logo-load.svg')"> </v-img>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "PageLoader",

  data() {
    return {
      active: false
    };
  },

  methods: {
    show({ active }) {
      this.active = active;
    }
  }
};
</script>
