export default [
  {
    path: "/comissao",
    component: () => import("@/views/comissao/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        component: () => import(`@/views/comissao/dashboard/Dashboard`),
        meta: { requiresAuth: true }
      },
      {
        path: "comissao",
        component: () => import("@/views/comissao/comissao/Comissao"),
        meta: { requiresAuth: true }
      },
      {
        path: "descritivo",
        component: () => import("@/views/comissao/descritivo/Descritivo"),
        meta: { requiresAuth: true }
      },
      {
        path: "departamento-comercial-consultor-vendas",
        component: () =>
          import("@/views/comissao/deptoComercial/ResumoConsultorVendas"),
        meta: { requiresAuth: true }
      },
      {
        path: "parametros-pos-vendas",
        component: () =>
          import("@/views/comissao/deptoPosVendas/ParametrosPosVendas"),
        meta: { requiresAuth: true }
      },

      {
        path: "administrativo-lancamento-manual",
        component: () =>
          import("@/views/comissao/deptoAdministrativo/LancamentoManual"),
        meta: { requiresAuth: true }
      },
      {
        path: "administrativo-exportar-listar",
        component: () =>
          import("@/views/comissao/deptoAdministrativo/ExportarListar"),
        meta: { requiresAuth: true }
      },
      {
        path: "meta-individual",
        component: () => import("@/views/comissao/metas/Individual"),
        meta: { requiresAuth: true }
      },
      {
        path: "meta-equipe",
        component: () => import("@/views/comissao/metas/Equipe"),
        meta: { requiresAuth: true }
      },
      {
        path: "meta-empresa",
        component: () => import("@/views/comissao/metas/Empresa"),
        meta: { requiresAuth: true }
      },
      {
        path: "meta-funcao",
        component: () => import("@/views/comissao/metas/Funcao"),
        meta: { requiresAuth: true }
      },
      {
        path: "funcoes-parametros-departamento",
        component: () =>
          import("@/views/comissao/funcoesParametros/ParametrosDepartamento"),
        meta: { requiresAuth: true }
      },
      {
        path: "funcoes-parametros-empresa",
        component: () =>
          import("@/views/comissao/funcoesParametros/ParametrosEmpresa"),
        meta: { requiresAuth: true }
      },
      {
        path: "funcoes-parametros-equipe",
        component: () =>
          import("@/views/comissao/funcoesParametros/ParametrosEquipe"),
        meta: { requiresAuth: true }
      },
      {
        path: "realizados-individual",
        component: () => import("@/views/comissao/realizados/Individual"),
        meta: { requiresAuth: true }
      },
      {
        path: "realizados-equipes",
        component: () => import("@/views/comissao/realizados/Equipes"),
        meta: { requiresAuth: true }
      },
      {
        path: "realizados-empresas",
        component: () => import("@/views/comissao/realizados/Empresas"),
        meta: { requiresAuth: true }
      },

      {
        path: "relatorios-empresa",
        component: () => import("@/views/comissao/relatorios/Empresa"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-depto-comercial",
        component: () => import("@/views/comissao/relatorios/DeptoComercial"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-depto-administrativo",
        component: () =>
          import("@/views/comissao/relatorios/DeptoAdministrativo"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-colaborador",
        component: () => import("@/views/comissao/relatorios/Colaborador"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-supervisor-equipe",
        component: () => import("@/views/comissao/relatorios/SupervisorEquipe"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-comissao",
        component: () => import("@/views/comissao/relatorios/Comissao"),
        meta: { requiresAuth: true }
      },
      {
        path: "relatorios-realizados",
        component: () => import("@/views/comissao/relatorios/Realizados"),
        meta: { requiresAuth: true }
      },

      {
        path: "processamento",
        component: () =>
          import("@/views/comissao/processamento/ProcessamentoComissao"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
