import Vue from "vue";
import Swal from "sweetalert2";

// If you don't need the styles, do not connect
import "@/assets/css/sweetalert2.scss";

const SweetAlert = {
  confirm(
    text = "Confirmar",
    title = "",
    confirmText = "Confirmar",
    cancelText = "Cancelar"
  ) {
    return Swal.fire({
      title,
      text,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      cancelButtonColor: "#f54242",
      allowOutsideClick: false
    }).then(result => {
      if (result.isConfirmed) {
        return Promise.resolve();
      }
      return Promise.reject({
        toString: () => "Confirmação rejeitada"
      });
    });
  }
};

Vue.prototype.$swal = SweetAlert;
export default SweetAlert;
