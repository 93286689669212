import http from "../../../services/http";
import empresaService from "../../../services/empresa";

export default {
  fetchEmpresas: async ({ commit }, filters) => {
    commit("changeRequestStatus", "loading");
    try {
      const response = await http.get("/sistemas/empresas", {
        params: { ...filters }
      });

      commit("pushEmpresas", response.data);
      commit("changeRequestStatus", "success");
    } catch (error) {
      console.error("Action.empresa/fetchEmpresas", error);
      commit("changeRequestStatus", "error");
      return Promise.reject(new Error(error));
    }
  },

  fetchEquipes: async ({ commit }, filters) => {
    try {
      let response = await empresaService.getEquipes(filters);
      commit("setEquipes", response.data);
    } catch (error) {
      console.error("fetchEquipes", error);
      return Promise.reject(new Error(error));
    }
  }
};
