import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifyMoney from "vuetify-money";
import { VueMaskDirective } from "v-mask";
import swal from "./plugins/swal";
import handlePermissions from "./plugins/canIAcess";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import loadDrillDown from "highcharts/modules/drilldown";
import Notify from "./support/notification/Notify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Loader from "./support/loader/Loader";

Vue.use(VuetifyMoney);
loadDrillDown(Highcharts);
HighchartsNoData(Highcharts);
Vue.use(HighchartsVue, { Highcharts });
Vue.config.productionTip = false;

Vue.directive("mask", VueMaskDirective);
Vue.filter("TitleCase", value => {
  return value
    ? value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
    : "";
});

Vue.filter("Currency", value => {
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
});

Vue.use(Loader);
Vue.use(Notify);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
Vue.use(handlePermissions);

new Vue({
  router,
  store,
  vuetify,
  swal,
  render: h => h(App)
}).$mount("#app");
