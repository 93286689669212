import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/authStore";
import empresa from "./modules/empresa";
import campanha from "./modules/campanha";
import comissao from "./modules/comissao";
import procedure from "./modules/procedure";
import sistema from "./modules/sistema";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    empresa,
    campanha,
    comissao,
    procedure,
    sistema
  }
});
