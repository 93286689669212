import procedureService from "@/services/procedures";

export default {
  fetchProcedures: async ({ commit }, filters) => {
    try {
      const response = await procedureService.getProcessos({ ...filters });
      commit("setProcedures", response.data);
    } catch (error) {
      console.error("Action.procedure/fetchProcedures", error);
      return Promise.reject(new Error(error));
    }
  }
};
