<template>
  <v-app>
    <notifications />
    <router-view></router-view>
    <Dialog ref="dialog"></Dialog>
  </v-app>
</template>

<script>
import Dialog from "@/support/dialoger/Dialoger.vue";

export default {
  name: "App",
  components: {
    Dialog
  },

  mounted() {
    this.$root.dialog = this.$refs.dialog.show;
  }
};
</script>
