import Loader from "./Loader.vue";
import { events } from "./events";

const Pageloader = {
  install(Vue) {
    if (this.installed) return;

    this.installed = true;

    Vue.component("dialoger", Loader);

    const load = params => {
      if (typeof params === "object") {
        events.$emit("show", params);
      }
    };

    Vue.prototype["$load"] = load;
    Vue["load"] = load;
  }
};

export default Pageloader;
