import http from "./http";

const procedures = {
  processa: data => http.post("/comissoes/procedures/processa", data),

  //processos
  getProcessos: filters =>
    http.get("/comissoes/procedures/processos", { params: { ...filters } }),

  postProcessos: data => http.post("/comissoes/procedures/processos", data),

  putProcessos: (id, data) => {
    return http.put(`/comissoes/procedures/processos/${id}`, data);
  },

  deleteProcessos: id => http.delete(`/comissoes/procedures/processos/${id}`),

  //Empresas vinculadas a processos

  getEmpresasByProcessos: filters =>
    http.get("/comissoes/processos/empresas", { params: { ...filters } }),

  postEmpresasByProcessos: data =>
    http.post("/comissoes/processos/empresas", data),

  putEmpresasByProcessos: (id, data) => {
    return http.put(`/comissoes/processos/empresas/${id}`, data);
  },

  deleteEmpresasByProcessos: id =>
    http.delete(`/comissoes/processos/empresas/${id}`),

  // Parametros dos processos

  getAllParametros: filters =>
    http.get("/comissoes/processos/parametros", { params: { ...filters } }),

  getParametroById: id => http.get(`/comissoes/processos/parametros/${id}`),

  putParametros: (id, data) =>
    http.put(`/comissoes/processos/parametros/${id}`, data),

  deleteParametros: id => http.delete(`/comissoes/processos/parametros/${id}`),

  getProc: filters =>
    http.get("/comissoes/processos/parametros/proc", {
      params: { ...filters }
    }),

  createParametros: data => http.post("/comissoes/processos/parametros", data)
};

export default procedures;
