export default {
  getEmpresas: state => {
    if (state.list.data) {
      return state.list.data.map(empresa => empresa);
    }
    return [];
  },

  getEmpresasAtivaCampanha: state => {
    if (state.list.data) {
      return state.list.data.filter(empresa => (empresa.ativa_campanha = "S"));
    }
    return [];
  },

  getLoadingState: state => {
    if (state.requestStatus == "loading") {
      return true;
    }
    return false;
  },

  getFormEmpresa: state => {
    if (state.form) {
      return state.form;
    }
    return false;
  },

  getEquipes: state => {
    if (state.equipes.data) {
      return state.equipes.data;
    }
    return [];
  }
};
