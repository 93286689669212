export default {
  getTiposFechamento: state => {
    if (state.tiposFechamento.data) {
      return state.tiposFechamento.data;
    }
    return [];
  },

  getTiposDados: state => {
    if (state.tiposDados.data) {
      return state.tiposDados.data;
    }
    return [];
  },

  getProcessamento: state => {
    if (state.processamento.data) {
      return state.processamento.data;
    }
    return [];
  },

  getTiposEvento: state => {
    if (state.tiposEvento.data) {
      return state.tiposEvento.data;
    }
    return [];
  }
};
