import store from "@/store";

const handlePermissions = {
  install: function(Vue) {
    Vue.mixin({
      data() {
        return {
          userPermissions: store.getters.getUser.permission,
          permissionTypes: {
            number: permission =>
              this.userPermissions?.some(item => item === permission),
            object: permissions =>
              permissions?.some(item => this.userPermissions?.includes(item))
          }
        };
      },
      methods: {
        canAccess(permissions) {
          return this.verifyArgType(permissions);
        },

        verifyArgType(permissions) {
          const type = typeof permissions;
          return this.permissionTypes[type](permissions);
        }
      },

      directives: {
        "can-access": {
          bind(el, binding, vnode) {
            const canAccess = vnode?.context?.canAccess;

            if (!canAccess(binding.value)) {
              const comment = document.createComment(" ");
              Object.defineProperty(comment, "setAttribute", {
                value: () => undefined
              });
              vnode.elm = comment;
              vnode.text = " ";
              vnode.isComment = true;
              vnode.context = undefined;
              vnode.tag = undefined;
              vnode.data.directives = undefined;

              if (vnode.componentInstance) {
                vnode.componentInstance.$el = comment;
              }

              if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
              }
            }
          }
        }
      }
    });
  }
};

export default handlePermissions;
