export default {
  getUsers: state => {
    if (state.users.data) {
      return state.users;
    }
    return [];
  },
  getPermissions: state => {
    if (state.permissions) {
      return state.permissions;
    }
    return [];
  },

  getUserPermissions: state => {
    if (state.userPermissions) {
      return state.userPermissions;
    }
    return [];
  },

  getEmpresasVinculadasAoUsuario: state => {
    if (state.empresasVinculadasAoUsuario) {
      return state.empresasVinculadasAoUsuario;
    }
    return [];
  },

  getEmpresasNaoVinculadasAoUsuario: state => {
    if (state.empresasNaoVinculadasAoUsuario) {
      return state.empresasNaoVinculadasAoUsuario;
    }
    return [];
  },
  getView: state => {
    if (state.views) {
      return state.views;
    }
    return [];
  },
  getHiddenValue: state => {
    if (state.hiddenValue.status == true) {
      return true;
    }
    return false;
  }
};
