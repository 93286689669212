const defaultState = () => {
  return {
    list: [],
    requestStatus: "",
    currentCampanha: "",
    funcoes: [],
    campanha: {},
    empresas: [],
    indicadores: [],
    indicesCrescimento: [],
    tipoDados: [],
    metasCadastradas: [],
    metaProgressiva: {
      loading: false,
      faixas: {
        data: []
      }
    }
  };
};

export default {
  pushCampanhas(state, campanhas) {
    state.list = campanhas;
  },
  pushAprovacaoCampanhas(state, campanhas) {
    state.listAprovacoesCampanha = campanhas;
  },
  setFuncoes(state, funcoes) {
    state.funcoes = funcoes;
  },
  setPublicoAlvoSelecionado(state, publicoSelecionado) {
    state.publicoAlvoSelecionado = publicoSelecionado;
  },
  changeRequestStatus(state, status) {
    state.requestStatus = status;
  },
  setCurrentCampanha(state, idCampanha) {
    state.currentCampanha = idCampanha;
  },
  setCampanha(state, campanha) {
    state.campanha = campanha;
  },
  setListaIndicadores(state, indicadores) {
    state.indicadores = indicadores;
  },
  setListaIndicesCrescimento(state, indices) {
    state.indicesCrescimento = indices;
  },
  setMetasCadastradas(state, metas) {
    state.metasCadastradas = metas;
  },
  setFaixasMetaProgressiva(state, faixas) {
    state.metaProgressiva.faixas = faixas;
  },
  //EMPRESAS
  setEmpresas(state, empresas) {
    state.empresas = empresas;
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },

  //Metas
  setMetaEmpresa(state, metas) {
    state.metas.metaEmpresa = metas;
  },

  setMetaEquipe(state, metas) {
    state.metas.metaEquipe = metas;
  },

  setMetaIndividual(state, metas) {
    state.metas.metaIndividual = metas;
  },

  //Realizados
  setRealizadoEmpresa(state, realizados) {
    state.realizados.realizadoEmpresa = realizados;
  },

  setRealizadoEquipe(state, realizados) {
    state.realizados.realizadoEquipe = realizados;
  },

  setRealizadoIndividual(state, realizados) {
    state.realizados.realizadoIndividual = realizados;
  },

  // Apuracao de campanha
  setApuracaoTitle(state, playload) {
    state.apuracao.title = playload;
  },
  setApuracaoFilters(state, playload) {
    Object.assign(state.apuracao.filters, playload);
  },
  setApuracaoDestroy(state) {
    state.apuracao = {
      title: "Apuração de Campanha",
      filters: {},
      itemsPublicoAlvoCampanha: [],
      itemsColaboradorCampanha: []
    };
  },

  setApuracaoPublicoAlvoCampanha(state, playload) {
    state.apuracao.itemsPublicoAlvoCampanha = playload;
  },
  setApuracaoColaboradorCampanha(state, playload) {
    state.apuracao.itemsColaboradorCampanha = playload.sort(
      (a, b) => new Date(a.data_inicio) - new Date(b.data_inicio));
  },

  setApuracaoCampanha(state, playload) {
    state.apuracao.campanha = playload;
  },

  //Campanha Equipe
  setEquipesVinculadasCampanha(state, equipe) {
    state.equipesVinculadasCampanha = equipe;
  },

  setEquipes(state, equipes) {
    state.equipes = equipes;
  },

  setEquipesNaoVinculadasCampanha(state, equipe) {
    state.equipesNaoVinculadasCampanha = equipe;
  },

  setCampanhasEquipes(state, equipe) {
    state.campanhasEquipes = equipe;
  },

  setValidBtnNextStepFrom(state, status) {
    state.validBtnNextStepFrom = status;
  },

  setSelectedFilters(state, value) {
    state.selected_filters[value.name] = value;
  },
  cleanSelectedFilters(state) {
    state.selected_filters = {};
  }
};
