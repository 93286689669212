export default [
  {
    path: "/colaboradores",
    component: () => import("@/views/colaborador/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/consulta-pessoa",
        name: "consulta-pessoa",
        component: () => import("@/views/colaborador/ConsultaColaboradores"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-colaboradores-empresa",
        name: "consultaColaboradoresEmpresa",
        component: () =>
          import(
            "@/views/colaborador/ColaboradoresEmpresa/ConsultaColabEmpresa"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-colaborador-equipe",
        name: "consulta-colaborador-equipe",
        component: () => import("@/views/colaborador/ColaboradorEquipe"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-funcoes",
        name: "consulta-funcoes",
        component: () => import("@/views/colaborador/ConsultaFuncoes"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-funcao-nivel",
        name: "consulta-funcao-nivel",
        component: () => import("@/views/colaborador/ConsultaFuncaoNivel"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
