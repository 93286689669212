export default [
  {
    path: "/perfil",
    component: () => import("@/views/campanha/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "perfil",
        component: () => import("@/views/user/Perfil"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
