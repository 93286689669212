export default {
  setTiposFechamento(state, tipos) {
    state.tiposFechamento = tipos;
  },

  setTiposDados(state, tipos) {
    state.tiposDados = tipos;
  },

  setProcessamento(state, processos) {
    state.processamento = processos;
  },

  setTiposEvento(state, tiposEvento) {
    state.tiposEvento = tiposEvento;
  }
};
